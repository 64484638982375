<template>
    <div class="template">
        <div style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;">
            <div style="width: 1500px; margin:80px auto; overflow: hidden;">
                <div class="tel-top">
                    <div style="display: flex">
                        <div class="hand" @click="checkout = 'my'" :class="checkout === 'my' ? 'tel-select' : 'tel-tab'">我的模板</div>
                        <div class="hand" @click="checkout = 'friend'" :class="checkout === 'friend' ? 'tel-select' : 'tel-tab'">好友模板</div>
                    </div>
<!--                    <div>-->
<!--                        <el-input placeholder="请输入关键词搜索" v-model="select" style="width: 342px">-->
<!--                            <el-button class="hand op" slot="append" icon="el-icon-search"></el-button>-->
<!--                        </el-input>-->
<!--                    </div>-->
                </div>

                <div class="tel-body" v-if="checkout === 'my'">
                    <div style="display: flex; flex-wrap: wrap;">
                        <div class="tel-box">
                            <div style="text-align: center; margin-top: 60px" class="hand op" @click="utils.b('/form/diy')">
                                <i class="el-icon-folder-add" style="font-size:100px; margin: 14px 0; color: #2970FF"></i>
                                <div style="color: #999999; font-size: 20px">新建自定义模板</div>
                            </div>
                        </div>
                        <div v-for="(item, index) in templateList" :key="index" class="tel-box" @mousemove="item.show = true" @mouseleave="item.show = false">
                            <div style="width: 100%; height: 244px; background-image: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/telBack.png')">
                                <div style="background: rgba(0,0,0,0.6); height: 244px" v-if="item.show === true">
                                    <div style="display: flex; justify-content: end; color: #FFFFFF; font-size: 18px">
                                        <i class="hand op el-icon-delete" style="margin: 12px 12px 0 0" @click="deleteTemplate(item.id)"></i>
                                    </div>

                                    <div style="display: flex; justify-content: center; color: #FFFFFF; margin-top: 10px">
                                        <div class="hand op" style="margin-right: 42px" @click="showTemplateInfo(item)">
                                            <img src="./imgs/look.png">
                                            <div style="text-align: center">预览</div>
                                        </div>
                                        <div class="hand op" @click="$router.push({path: '/form/diy', query: {id: item.id, name: item.title}})">
                                            <img src="./imgs/write.png">
                                            <div style="text-align: center">编辑</div>
                                        </div>
                                    </div>

                                    <div style="display: flex; justify-content: center; color: #FFFFFF; margin-top: 20px;">
                                        <div class="hand op" style="margin-right: 28px" @click="toFriend(item.id)">
                                            <img src="./imgs/return.png" style="margin-left: 8px">
                                            <div style="text-align: center">好友分享</div>
                                        </div>
                                        <div class="hand op" @click="inRoom(item.id)">
                                            <img src="./imgs/return.png" style="margin-left: 10px">
                                            <div style="text-align: center">Room分享</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="padding: 14px 32px;text-align: center">
                                <div>{{item.title}}</div>
                                <hr style="margin: 6px 0">
                                <div style="color: #999999; font-size: 14px">{{item.createTime}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="checkout === 'friend'">
                    <div style="display: flex">
                        <div class="left-friend">
                            <div style="font-size: 20px; font-weight: 600; margin-bottom: 12px">我的好友</div>
                            <div v-for="(item, index) in friendsList" :key="index">
                                <div class="hand op" :index="String(item.id)" style="display: flex; font-weight: 600; height: 42px; line-height: 40px; margin-bottom: 10px" @click="item.show = !item.show">
                                    <i :class="item.show === false ? 'el-icon-caret-bottom' : 'el-icon-caret-right'" style="margin: 10px 0; font-size: 20px;"></i>
                                    <div style="margin-left: 20px; font-size: 16px">{{item.name}}（{{item.friendArr.length}}）</div>
                                </div>
                                <div v-if="item.show === false">
                                    <div>
                                        <div v-for="(friend, key) in item.friendArr" :key="key" :index="friend.friendInfo.uuid" @click="openInfo(friend.friendInfo)" class="hand op" style="margin-bottom: 10px">
                                            <div style="display: flex;width: 100%; height: 36px; padding: 4px" class="tab-checkout">
                                                <img :src="friend.friendInfo.headIco" style="width: 36px; height: 36px;border-radius: 50%; margin-right: 6px"/>
                                                <div>
                                                    <div style="width: 100%; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; line-height: 20px;">{{friend.friendInfo.nickName}}</div>
                                                    <div style="width: 100%; font-size: 12px; color: #AAAAAA; overflow: hidden;line-height: 20px;text-overflow:ellipsis;">{{friend.friendInfo.major}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="width: 1248px">
                            <div v-if="JSON.stringify(userInfo) == '{}'" class="right-top">选择好友</div>
                            <div v-else class="right-top">
                                <div style="display: flex">
                                    <img :src="userInfo.headIco + '-200x200'" class="head-ico">
                                    <div>
                                        <div style="display: flex; margin-bottom: 12px">
                                            <span style="font-size: 26px; margin-right: 26px; font-weight: 600">{{userInfo.nickName}}</span>
                                            <span v-show="userInfo.userType == 3" style="width: 90px; height: 22px; background-color: #F5CF0E; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px; margin-right: 20px;">企业子账号</span>
                                            <span v-show="userInfo.userType == 2 && userInfo.organizationType == 1" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">企业账号</span>
                                            <span v-show="userInfo.userType == 2 && userInfo.organizationType == 2" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">机关账号</span>
                                            <span v-show="userInfo.userType == 2 && userInfo.organizationType == 3" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">社团账号</span>
                                            <span v-show="userInfo.userType == 2 && userInfo.organizationType == 4" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">工作室账号</span>
                                            <img v-show="userInfo.authenticationStatus >= 1 && userInfo.userType == 1" src="./imgs/name.png" style="margin-right: 20px; margin-top: 6px; width: 70px; height: 26px;"/>
                                            <img v-show="userInfo.authenticationStatus >= 2" src="./imgs/major.png" style="margin-right: 20px; margin-top: 6px; width: 70px; height: 26px;"/>
                                            <img v-show="userInfo.authenticationStatus >= 3" src="./imgs/expert.png" style="margin-right: 20px; margin-top: 6px; width: 70px; height: 26px;"/>
                                            <img v-show="userInfo.v == 1" src="./imgs/vip1.png" style="margin-right: 20px; margin-top: 8px; width: 24px; height: 24px;"/>
                                        </div>
                                        <div style="font-size: 16px">
                                            <span style="color: #999999; margin-right: 20px">乌鲁木齐</span>
                                            <span style="color: #999999; margin-right: 20px"> | </span>
                                            <span style="color: #999999; margin-right: 20px" v-if="userInfo.userType == 2">{{userInfo.nickName}}官方账号</span>
                                            <span style="color: #999999; margin-right: 20px" v-else-if="userInfo.userType == 3">{{userInfo.fidName}}官方子账号</span>
                                            <span style="color: #999999; margin-right: 24px" v-else>{{userInfo.major}}</span>
                                            <span style="color: #999999; margin-right: 20px"> | </span>
                                            <span style="color: #1479FF; margin-right: 20px">关注</span>
                                            <span style="margin-right: 20px">{{userInfo.myFollowNum}}</span>
                                            <span style="color: #999999; margin-right: 20px"> | </span>
                                            <span style="color: #1479FF; margin-right: 20px">粉丝</span>
                                            <span style="margin-right: 20px">{{userInfo.fansNum}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="right-body" v-if="fTemplateList.length > 0">
                                <div v-for="(item, index) in fTemplateList" :key="index" class="tel-box" @mousemove="item.show = true" @mouseleave="item.show = false">
                                    <div style="width: 100%; height: 244px; background-image: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/telBack.png')">
                                        <div style="background: rgba(0,0,0,0.6); height: 244px" v-if="item.show === true">
                                            <div style="display: flex; justify-content: center; color: #FFFFFF; padding: 70px 0">
                                                <div class="hand op" style="margin-right: 30px" @click="showTemplateInfo(item)">
                                                    <img src="./imgs/look.png">
                                                    <div style="text-align: center">预览</div>
                                                </div>
                                                <div class="hand op" @click="requestTemplate(item.id)">
                                                    <img src="./imgs/reseve.png" style="margin-left: 4px">
                                                    <div>请求分享</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="padding: 14px 32px;text-align: center">
                                        <div>{{item.title}}</div>
                                        <hr style="margin: 6px 0">
                                        <div style="color: #999999; font-size: 14px">{{item.createTime}}</div>
                                    </div>
                                </div>
                            </div>
                          <div class="right-body" v-else>
                            <NullBox style="width: 100%; text-align: center" data="自定义模板"></NullBox>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--邀请好友-->
        <el-dialog class="show-message" :visible.sync="showFriends" width="740px">
            <div style="display: flex;">
                <div class="dialog-title">分享私有模板</div>
            </div>

            <div class="message-divider">
                <el-divider></el-divider>
            </div>

            <div class="message-body">
                <div class="title-form">好友列表</div>
                <div v-for="(item, index) in friendsList" :key="index">
                    <div class="list-title" @click="item.show = !item.show"><i :class="!item.show ? 'el-icon-caret-right' : 'el-icon-caret-bottom'" style="font-size: 17px; margin: 0 22px"></i>{{item.name}}（{{item.friendArr.length}}）</div>
                    <div style="display: flex; flex-wrap: wrap" v-if="item.show === true">
                        <div style="display: flex; justify-content: center;" class="list-content" v-for="(friend, indexs) in item.friendArr" :key="indexs">
                            <img class="head-ico" style="width: 40px; height: 40px; margin: auto 6px;" :src="friend.friendInfo.headIco">
                            <div style="margin: auto 0; width: 70%">
                                <div style="font-size: 16px; font-weight: 600; margin-bottom: 2px">
                                    {{friend.friendInfo.nickName}}
                                </div>
                                <div style="color: #AAAAAA; font-size: 14px">
                                    乌鲁木齐 | {{friend.friendInfo.major}}
                                </div>
                            </div>
                            <div v-if="friend.select === false" @click="friend.select = true" class="hand op list-btn" style="color: #FF9800; border: 1px #FF9800 solid">选择好友</div>
                            <div v-if="friend.select === true"  @click="friend.select = false" class="hand op list-btn" style="color: #FFFFFF; background-color: #FF9800">取消</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="padding-bottom: 10px">
                <div @click="joinFriends" class="hand op" style="width: 180px; height: 40px; margin: 20px auto; text-align: center; line-height: 40px; border-radius: 5px; background-color: #2970FF; color: #FFFFFF; font-size: 16px; font-weight: 600">一键分享模板</div>
            </div>
        </el-dialog>

        <!--分享给Room-->
        <el-dialog class="show-message" :visible.sync="showRoom" width="740px">
            <div style="display: flex;">
                <div class="dialog-title">分享模板到Room</div>
            </div>
            <div class="message-divider">
                <el-divider></el-divider>
            </div>

            <div class="message-body">
                <div class="title-form">我的Room列表</div>
                <div style="display: flex; flex-wrap: wrap">
                    <div style="display: flex; justify-content: center;" class="list-content" v-for="(item, index) in myShowRoomList" :key="index">
                        <img class="head-ico" style="width: 40px; height: 40px; margin: auto 6px;" :src="item.cover">
                        <div style="margin: auto 0; width: 70%">
                            <div style="font-size: 16px; font-weight: 600; margin-bottom: 2px">
                                {{item.name}}
                            </div>
                        </div>
                        <div style="margin-top: 20px">
                            <el-radio v-model="roomId" :label="item.id"></el-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div style="padding-bottom: 10px">
                <div @click="joinRoom" class="hand op" style="width: 180px; height: 40px; margin: 20px auto; text-align: center; line-height: 40px; border-radius: 5px; background-color: #2970FF; color: #FFFFFF; font-size: 16px; font-weight: 600">分享</div>
            </div>
        </el-dialog>

        <!--查看模板弹窗-->
        <el-dialog class="show-message" :visible.sync="showTemplate" width="60%" @close="closeTemplate">
            <div style="display: flex;">
                <div class="dialog-title">查看模板</div>
            </div>
            <div class="message-divider">
                <el-divider></el-divider>
            </div>
            <div class="message-body">
                <div style="margin-bottom: 40px">
                    <form-builder v-if="showTemplate === true" ref="formBuilder" v-model="formVal" :buildData="formCode"></form-builder>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import FormBuilder from "../../components/common/formDesigner/formBuilder";
    import NullBox from "@/pages/room/components/task/NullBox.vue";
    export default {
        name: "template",
        components: {NullBox, FormBuilder},
        data() {
            return {
                select: '',
                checkout: 'my',

                templateList: [],

                showFriends: false,
                friendsList: [],

                showTemplate: false,
                formVal: '',
                formCode: '',

                userInfo: {},
                fTemplateList: [],
                friendId: '',

                showRoom: false,
                myShowRoomList: [],
                roomId: '',
                templateId: '',
            }
        },

        mounted() {
            this.getMyDiy();
            this.getFriendsList();
            this.getRoom()
        },

        methods: {
            // 请求好友的模板
            requestTemplate(id) {
                this.newApi.requestShareDiyTemplate({id: id}).then(res => {
                    this.utils.sus(res.data)
                })
            },

            // 获取Room
            getRoom() {
                this.newApi.getMyDutyRoomList({type: 0, roomType: 0}).then(ret =>{
                    this.myShowRoomList = ret.data;
                })
            },

            // 分享模板到room
            joinRoom() {
                this.newApi.shareTemplateToRoom({id: this.templateId, roomId: this.roomId}).then(res => {
                    if (res.isSuccess === 1) {
                        this.showRoom = false;
                        this.templateId = '';
                        this.roomId = '';
                        this.utils.sus(res.data)
                    }
                })
            },

            // 转发到Room
            inRoom(e) {
                this.showRoom = true;
                this.templateId = e;
            },

            // 点击好友获取模板
            openInfo(item) {
                this.userInfo = item;
                this.getFriendDiy(item.uuid);
            },

            // 删除模板
            deleteTemplate(id) {
                var that = this;
                this.utils.confirm('确定删除此自定义模板？', function () {
                    that.newApi.delMyDiyTemplate({id: id}).then(res =>{
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data);
                            that.getMyDiy();
                        }
                    })
                })
            },

            // 关闭模板弹窗
            closeTemplate() {
                this.formVal = '';
                this.formCode = ''
            },

            // 查看模板信息
            showTemplateInfo(item) {
                this.showTemplate = true;
                this.formVal = JSON.stringify(item.json.list);
                this.formCode = JSON.stringify(item.json)
            },

            // 获取我的模板文档
            getMyDiy() {
                this.newApi.getMyDiyTemplate().then(res =>{
                    for (const item of res.data) {
                        item.show = false
                    }
                    this.templateList = res.data
                })
            },

            // 获取好友模板文档
            getFriendDiy(uuid) {
                this.newApi.getMyFriendDiyTemplate({uuid: uuid}).then(res =>{
                    for (const item of res.data) {
                        item.show = false
                    }
                    this.fTemplateList = res.data
                })
            },

            // 获取好友列表
            getFriendsList() {
                this.newApi.getMyFriendGroupList().then(res => {
                    for (const item of res.data) {
                        item.show = false;
                        for (const friend of item.friendArr) {
                            friend.select = false
                        }
                    }
                    this.friendsList = res.data
                })
            },

            // 打开好友列表弹窗
            toFriend(id) {
                this.showFriends = true;
                this.friendId = id
            },

            // 一键分享
            joinFriends() {
                var params = {};
                params.id = this.friendId;
                params.userList = [];
                for (const item of this.friendsList) {
                    for (const i of item.friendArr) {
                        if (i.select === true) {
                            params.userList.push(i.friendInfo.uuid)
                        }
                    }
                }
                params.userList = JSON.stringify(params.userList);
                this.newApi.activeShareDiyTemplate(params).then(res =>{
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.showFriends = false;
                        this.friendId = '';
                    }
                })
            },

        },
    }
</script>

<style scoped>
    .template {
      min-height: 100vh;
    }

    .template .tel-top {
        display: flex;
        justify-content: space-between;
        margin: 36px 0 10px 0;
    }

    .template .tel-tab {
        width: 132px;
        height: 40px;
        font-weight: 600;
        margin-top: 6px;
        margin-right: 10px;
        font-size: 18px;
        text-align: center;
    }

    .template .tel-select {
        width: 132px;
        height: 26px;
        font-size: 18px;
        font-weight: 600;
        padding: 6px 0;
        margin-right: 10px;
        color: #FFFFFF;
        background-color: #5A6FE6;
        border-radius: 20px;
        text-align: center;
    }

    .template .tel-box {
        width: 284px;
        height: 324px;
        margin: 0 16px 36px 0;
        background-color: #FBFBFB;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .template .dialog-title {
        height: 30px;
        padding: 10px 0;
        font-weight: 600;
        margin: 0 auto;
        font-size: 20px;
    }

    .template .message-body {
        min-height: 200px;
        padding: 20px;
    }

    .template .el-divider--horizontal {
        margin: 0;
    }

    .template .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .template .list-title {
        width: 232px;
        height: 36px;
        font-size: 15px;
        line-height: 36px;
        font-weight: 600;
        margin-bottom: 10px;
        background-color: #F5F5F5;
        border: 1px #D4D6D9 solid;
    }

    .template .list-content {
        width: 308px;
        height: 64px;
        border: 1px #CED0D4 solid;
        margin: 0 20px 10px 0;
    }

    .template .list-btn {
        width: 78px;
        height: 24px;
        line-height: 24px;
        margin: auto 10px;
        border-radius: 5px;
        text-align: center;
    }

    .template .left-friend {
        width: 232px;
        margin-right: 10px;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin-bottom: 10px;
    }

    .template .tab-checkout:hover {
        background-color: #F5F5F5;
    }

    .template .right-top {
        padding: 20px 28px;
        background-color: #FFFFFF;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
        width: 100%;
        height: 80px;
        margin-bottom: 10px;
    }

    .template .right-body {
        display: flex;
        flex-wrap: wrap;
        padding: 24px;
        background-color: #FFFFFF;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
        width: 100%;
        margin-bottom: 10px;
    }

    .template .head-ico {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 20px;
    }

    /deep/ .show-message .el-radio__label {
        display: none;
    }

    /deep/ .el-dialog__header{
        padding: 0;
    }

    /deep/ .el-dialog__body{
        padding: 0;
    }
</style>
